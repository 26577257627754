<template>
  <div class="edit-amount-input-playground">
    <span class="description">no placeholder will use 0 as default:</span>
    <div class="edit-amount-wrapper">
      <edit-amount-input
        :updated-amount="updatedAmount1"
        :on-input="onInputAmount1"/>
    </div>

    <span class="description">placeholder 500.5 is used:</span>
    <div class="edit-amount-wrapper">
      <edit-amount-input
        :placeholder="500.5"
        :updated-amount="updatedAmount2"
        :on-input="onInputAmount2"/>
    </div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'EditAmountInputPlayground',
  components: {
    EditAmountInput: BaseUI.EditAmountInput,
  },

  data() {
    return {
      updatedAmount1: null,
      updatedAmount2: null,
    };
  },

  methods: {
    onInputAmount1(value) {
      this.updatedAmount1 = value;
    },
    onInputAmount2(value) {
      this.updatedAmount2 = value;
    },
  },
};
</script>

<style lang="scss" scoped>

  .edit-amount-input-playground {
    .edit-amount-wrapper {
      text-align: left;
      margin-bottom: 10px;
    }
    .description {
      font-family: SimplerPro;
      font-size: 14px;
    }
  }
</style>
