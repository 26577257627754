<template>
  <div class="popup-playground">
    <button class="open-popup" v-on:click="() => openPopup(1)">Open Popup with close option</button>
    <button class="open-popup" v-on:click="openPopup(2)">Open Popup with back option</button>
    <button class="open-popup" v-on:click="openPopup(3)">Open Popup one button</button>
    <button class="open-popup" v-on:click="openPopup(4)">Open Popup 2 buttons</button>
    <button class="open-popup" v-on:click="openPopup(5)">Open Popup with slot in title</button>

    <page-overlay :isOpen="popupOpened === 1">
      <popup :title="'זה פופאפ'"
             :close-action="closePopup">
        <template v-slot:content>
          <div>ואפשר לשים בו מה שבא</div>
        </template>
      </popup>
    </page-overlay>

    <page-overlay :isOpen="popupOpened === 2">
      <popup :title="'זה פופאפ'"
             :back-action="closePopup">
        <template v-slot:content>
          <div>ואפשר לשים בו מה שבא</div>
        </template>
      </popup>
    </page-overlay>

    <page-overlay :isOpen="popupOpened === 3">
      <popup :title="'זה פופאפ'"
             :buttons="[buttons[0]]"
             :close-action="closePopup">
        <template v-slot:content>
          <div>לא חייבים לשים פה כלום</div>
        </template>
      </popup>
    </page-overlay>

    <page-overlay :isOpen="popupOpened === 4">
      <popup :title="'זה פופאפ'"
             :buttons="buttons"
             :close-action="closePopup">
        <template v-slot:content>
          <div>לא חייבים לשים פה כלום</div>
        </template>
      </popup>
    </page-overlay>

    <page-overlay :isOpen="popupOpened === 5">
      <popup :close-action="closePopup">
        <template v-slot:title>
          זה פופאפ עם סלוט בטייטל
        </template>
        <template v-slot:content>
          <div>ואפשר לשים בו מה שבא</div>
        </template>
      </popup>
    </page-overlay>

  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import PageOverlay from '../../../base-components/PageOverlay.vue';

const BUTTONS = [
  {
    title: 'אישור',
    subtitle: 'אפשר גם תת-כותרת',
    action: () => {},
    variant: 'primary',
    size: 'slim',
  },
  {
    title: 'ביטול',
    action: () => {},
    variant: 'secondary',
    size: 'slim',
  },
];

export default {
  name: 'PopupPlayground',
  components: {
    PageOverlay,
    Popup: BaseUI.Popup,
  },
  data() {
    return {
      popupOpened: null,
    };
  },
  computed: {
    buttons() {
      return BUTTONS;
    },
  },
  methods: {
    openPopup(popupNum) {
      this.popupOpened = popupNum;
    },
    closePopup() {
      this.popupOpened = null;
    },
  },
};
</script>

<style lang="scss" scoped>

  .popup-playground {
    .open-popup {
      display: block;
      margin-top: 10px;
    }
  }
</style>
