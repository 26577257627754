<template>
  <div class="popup-playground">
    <button class="open-popup" v-on:click="toggleClosePopup">Open Popup with close option</button>
    <button class="open-popup" v-on:click="toggleBackPopup">Open Popup with back option</button>

    <page-overlay :isOpen="openClosePopup">
      <colored-popup :class="'primary'" :close-action="toggleClosePopup">
        <template v-slot:top-content>
          <div>למעלה</div>
        </template>
        <template v-slot:bottom-content>
          <div>למטה</div>
        </template>
      </colored-popup>
    </page-overlay>

    <page-overlay :isOpen="openBackPopup">
      <colored-popup :class="'warning'" :back-action="toggleBackPopup">
        <template v-slot:top-content>
          <div>למעלה</div>
        </template>
        <template v-slot:bottom-content>
          <div>למטה</div>
        </template>
      </colored-popup>
    </page-overlay>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import PageOverlay from '../../../base-components/PageOverlay.vue';

export default {
  name: 'PopupPlayground',
  components: {
    PageOverlay,
    ColoredPopup: BaseUI.ColoredPopup,
  },
  data() {
    return {
      openClosePopup: false,
      openBackPopup: false,
    };
  },
  methods: {
    toggleClosePopup() {
      this.openClosePopup = !this.openClosePopup;
    },
    toggleBackPopup() {
      this.openBackPopup = !this.openBackPopup;
    },
  },
};
</script>

<style lang="scss" scoped>

  .popup-playground {
    .open-popup {
      display: block;
      margin-top: 10px;
    }
  }
</style>
