<template>
  <div class="support-footer-playground">

    <support-footer />

  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'SupportFooterPlayground',
  components: {
    SupportFooter: BaseUI.SupportFooter,
  },
};
</script>

<style lang="scss" scoped>

  .support-footer-playground {
    direction: rtl;
  }
</style>
