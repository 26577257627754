import { render, staticRenderFns } from "./ColoredPopupPlayground.vue?vue&type=template&id=57fdf18e&scoped=true&"
import script from "./ColoredPopupPlayground.vue?vue&type=script&lang=js&"
export * from "./ColoredPopupPlayground.vue?vue&type=script&lang=js&"
import style0 from "./ColoredPopupPlayground.vue?vue&type=style&index=0&id=57fdf18e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57fdf18e",
  null
  
)

export default component.exports