<template>
  <div class="card-playground">
    <card>
      <template v-slot:default>
        This is a card
      </template>
    </card>

    <card>
      <template v-slot:default>
        This is also a card
      </template>
      <template v-slot:footer>
        but with footer!!
      </template>
    </card>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'CardPlayground',
  components: {
    Card: BaseUI.Card,
  },
};
</script>

<style lang="scss" scoped>

  .card-playground {
    display: flex;
    flex-direction: column;
    align-items: center;

    .card {
      margin: 10px 0;
    }
  }
</style>
