<template>
  <div class="dropdown-playground">
    <plain-dropdown class="categories" :options="getOptions" :first-selected="selected" :on-select="onSelect"/>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'DropdownPlayground',
  components: {
    PlainDropdown: BaseUI.PlainDropdown,
  },
  data() {
    return {
      selected: 'אופציה 2',
    };
  },
  computed: {
    getOptions() {
      return ['אופציה 1', 'אופציה 2', 'אופציה 3'];
    },
  },
  methods: {
    onSelect(value) {
      this.selected = value;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
