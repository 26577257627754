<template>
  <div class="playground">
    <div class="title">Base-UI Playground</div>
    <div class="playground-component">
      <div class="separator"></div>
      <div class="component-name">
        RiseupBot
      </div>
      <riseup-bot-playground />
    </div>

    <div class="playground-component">
      <div class="separator"></div>
      <div class="component-name">
        RiseupButton
      </div>
      <riseup-button-playground />
    </div>

    <div class="playground-component">
      <div class="separator"></div>
      <div class="component-name">
        Card
      </div>
      <card-playground />
    </div>

    <div class="playground-component">
      <div class="separator"></div>
      <div class="component-name">
        ClosedCard
      </div>
      <closed-card-playground />
    </div>

    <div class="playground-component">
      <div class="separator"></div>
      <div class="component-name">
        EditAmountInput
      </div>
      <edit-amount-input-playground />
    </div>

    <div class="playground-component">
      <div class="separator"></div>
      <div class="component-name">
        Popup
      </div>
      <popup-playground />
    </div>

    <div class="playground-component">
      <div class="separator"></div>
      <div class="component-name">
        ColoredPopup
      </div>
      <colored-popup-playground />
    </div>

    <div class="playground-component">
      <div class="separator"></div>
      <div class="component-name">
        ColoredLinePopup
      </div>
      <colored-line-popup-playground />
    </div>

    <div class="playground-component">
      <div class="separator"></div>
      <div class="component-name">
        Dropdown
      </div>
      <dropdown-playground />
    </div>

    <div class="playground-component">
      <div class="separator"></div>
      <div class="component-name">
        PlainDropdown
      </div>
      <plain-dropdown-playground />
    </div>

    <div class="playground-component">
      <div class="separator"></div>
      <div class="component-name">
        SupportFooter
      </div>
      <support-footer-playground />
    </div>

    <div class="playground-component">
      <div class="separator"></div>
      <div class="component-name">
        TextInput
      </div>
      <text-input-playground />
    </div>

    <h2>Authentication</h2>

    <riseup-button class='button' :action="sendGetToken" :variant="'danger'" :title="'send get token'"></riseup-button>
    <label for="auth-code">Auth code to validate</label>
    <input v-model="authCode" id="auth-code" />
    <riseup-button class='button' :action="sendValidate" :variant="'danger'" :title="'send validate'"></riseup-button>
    <riseup-button class='button' :action="authenticatedRequest" :variant="'danger'" :title="'send Authenticated Request'"></riseup-button>

    <label for="customer-token">Customer token to assign</label>
    <input v-model="customerToken" id="customer-token" />
    <riseup-button class='button' :action="loginAs" :variant="'danger'" :title="'Login-as'"></riseup-button>
    <riseup-button class='button' :action="logOut" :variant="'danger'" :title="'Log out'"></riseup-button>

    <div style="font-weight: bold;">{{ statusCode }}</div>
    <div style="color:green">{{ response }}</div>
    <div style="color:red">{{ error }}</div>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

import RiseupBotPlayground from './components/RiseupBotPlayground.vue';
import RiseupButtonPlayground from './components/RiseupButtonPlayground.vue';
import CardPlayground from './components/CardPlayground.vue';
import ClosedCardPlayground from './components/ClosedCardPlayground.vue';
import EditAmountInputPlayground from './components/EditAmountInputPlayground.vue';
import PopupPlayground from './components/PopupPlayground.vue';
import ColoredPopupPlayground from './components/ColoredPopupPlayground.vue';
import ColoredLinePopupPlayground from './components/ColoredLinePopupPlayground.vue';
import DropdownPlayground from './components/DropdownPlayground.vue';
import PlainDropdownPlayground from './components/PlainDropdownPlayground.vue';
import SupportFooterPlayground from './components/SupportFooterPlayground.vue';
import TextInputPlayground from './components/TextInputPlayground.vue';

import { client } from '../../http';

export default {
  name: 'Playground',
  components: {
    RiseupBotPlayground,
    RiseupButtonPlayground,
    CardPlayground,
    ClosedCardPlayground,
    PopupPlayground,
    ColoredPopupPlayground,
    DropdownPlayground,
    EditAmountInputPlayground,
    ColoredLinePopupPlayground,
    PlainDropdownPlayground,
    SupportFooterPlayground,
    TextInputPlayground,
    RiseupButton: BaseUI.RiseupButton,
  },
  data() {
    return {
      authCode: '123456',
      customerToken: '',
      phoneNumber: '0502409046',
      response: '',
      error: '',
      statusCode: '',
    };
  },
  computed: {
  },
  methods: {
    async sendValidate() {
      await this.sendPostRequest('/api/no-auth/login/authenticate', { phoneNumber: this.phoneNumber, code: this.authCode });
    },
    async sendGetToken() {
      await this.sendPostRequest('/api/no-auth/login/generate-code', { phoneNumber: this.phoneNumber });
    },
    async authenticatedRequest() {
      await this.sendGetRequest('/api/credentials');
    },
    async loginAs() {
      await this.sendPostRequest('/api/no-auth/login-as', { customerToken: this.customerToken });
    },
    async logOut() {
      await this.sendGetRequest('/api/logout');
    },
    async sendPostRequest(url, data) {
      await this.sendRequest(client.post, [url, data]);
    },
    async sendGetRequest(url) {
      await this.sendRequest(client.get, [url]);
    },
    async sendRequest(func, params) {
      this.error = '';
      this.response = '';
      this.statusCode = '';
      try {
        this.response = await func(...params);
        this.statusCode = this.response.status;
      } catch (error) {
        this.statusCode = error.response.status;
        this.error = JSON.stringify(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .playground {
    font-family: SimplerPro;
    direction: ltr;
    width: 400px;

    .title {
      font-size: 30px;
      font-weight: bold;
      text-align: center;
    }

    .playground-component {
      margin-top: 20px;
      margin-bottom: 15px;

      .separator {
        height: 1px;
        width: 100%;
        background: $riseup_gray_0;
      }

      .component-name {
        margin-top: 5px;
        font-weight: bold;
      }
    }
  }

  .button {
    margin: 10px;
  }

  label {
    display: inline-block;
  }
</style>
