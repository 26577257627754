<template>
  <div class="closed-card-playground">
    <closed-card :class="'warning'"
                 :title="'זו הכותרת'"
                 :sub-title="'וזו תת הכותרת.   class warning'" />

    <closed-card :class="'danger'"
                 :title="'200'"
                 :sub-title="'class danger'" />

    <closed-card :class="'success'"
                 :title="'200'"
                 :sub-title="'class success'" />

    <closed-card :class="'disabled'"
                 :title="'200'"
                 :sub-title="'class disabled'" />

  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'ClosedCardPlayground',
  components: {
    ClosedCard: BaseUI.ClosedCard,
  },
};
</script>

<style lang="scss" scoped>

  .closed-card-playground {
    display: flex;
    flex-direction: column;
    align-items: center;

    .closed-card {
      margin: 10px 0;
    }
  }
</style>
