<template>
  <div class="text-input-playground">

    <text-input
      :autofocus="false"
      :labelText="'הכנס רק מספרים'"
      :value="numValue"
      :onInput="setNumValue"
      :error="error"
      :topLeftLabel="errorLabel"/>

  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'TextInputPlayground',
  components: {
    TextInput: BaseUI.TextInput,
  },
  data() {
    return {
      value: '',
      numValue: '',
      error: false,
      errorLabel: '',
    };
  },
  methods: {
    setValue(text) {
      this.value = text;
    },
    setNumValue(text) {
      this.numValue = text;
      const reg = /^\d+$/;
      this.error = !reg.test(text);
      this.errorLabel = this.error ? 'לא הכנסת רק מספרים' : '';
    },
  },
};
</script>

<style lang="scss" scoped>

  .text-input-playground {
    direction: rtl;
    margin-top: 20px;
  }
</style>
