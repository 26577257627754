<template>
  <div class="riseup-bot-playground">
    <riseup-bot />
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'RiseupBotPlayground',
  components: {
    RiseupBot: BaseUI.RiseupBot,
  },
};
</script>

<style lang="scss" scoped>

  .riseup-bot-playground {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
