<template>
  <div class="dropdown-playground">
    <cashflow-category-dropdown class="categories" :options="getCategories" :first-selected="selected" :on-select="onSelect" />
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

const VARIABLE_CATEGORY = { icon: '🤹‍', label: 'משתנה', expense: 'משתנות', style: 'warning' };
const TRACKING_CATEGORIES = [
  { icon: '🚗', label: 'רכב', expense: 'רכב', style: 'primary' },
  { icon: '🥤', label: 'אוכל בחוץ', expense: 'אוכל בחוץ', style: 'primary' },
  { icon: '🚌', label: 'תחבורה ציבורית', expense: 'תחבורה ציבורית', style: 'primary' },
];
const FIXED_CATEGORY = { icon: '🤹‍', label: 'קבועה', expense: 'קבועות', style: 'danger', separator: true };

export default {
  name: 'DropdownPlayground',
  components: {
    CashflowCategoryDropdown: BaseUI.CashflowCategoryDropdown,
  },
  data() {
    return {
      selected: null,
    };
  },
  computed: {
    getCategories() {
      return [VARIABLE_CATEGORY, ...TRACKING_CATEGORIES, FIXED_CATEGORY];
    },
  },
  methods: {
    onSelect(value) {
      this.selected = value;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
