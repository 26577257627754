<template>
  <div class="riseup-button-playground">

    <riseup-button :action="() => {}"
                   :title="'כותרת'"
                   :subtitle="'תת כותרת - לא חובה'"/>

    <riseup-button :action="() => {}"
                   :variant="'secondary'"
                   :title="'variant: secondary'" />

    <riseup-button :action="() => {}"
                   :variant="'secondary-black'"
                   :title="'variant: secondary-black'" />

    <riseup-button :action="() => {}"
                   :variant="'warning'"
                   :title="'variant: warning'" />

    <riseup-button :action="() => {}"
                   :variant="'danger'"
                   :title="'variant: danger'" />

    <riseup-button :action="() => {}"
                   :variant="'success'"
                   :title="'variant: success'" />

    <riseup-button :action="() => {}"
                   :title="'קטני'"
                   :subtitle="'וגם פה אפשר תת כותרת'"
                   :size="'small'"/>

  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'RiseupButtonPlayground',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
};
</script>

<style lang="scss" scoped>

  .riseup-button-playground {
    display: flex;
    flex-direction: column;
    align-items: center;

    .riseup-button {
      margin: 5px 0;
    }
  }
</style>
